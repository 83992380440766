import { getStringWithoutLeadingSlash } from 'helpers/parsing/stringWithoutSlash';
import { getUrlWithoutGetParameters } from 'helpers/parsing/urlParsing';

export const getSlugFromUrl = (originalUrl: string): string => {
    return getStringWithoutLeadingSlash(getUrlWithoutGetParameters(originalUrl));
};

export const getSlugFromServerSideUrl = (originalUrl: string): string => {
    const urlSegments = originalUrl.split('/');

    return urlSegments[urlSegments.length - 1].split('.')[0].split('?')[0]; // remove extension from segment
};

export const getCatNumFromServerSideUrl = (originalUrl: string): string | null => {
    if (originalUrl.includes('product/') || originalUrl.includes('video/')) {
        const urlSegments = originalUrl.split('/');

        return urlSegments[urlSegments.length - 1] ?? null; // remove extension from segment
    }

    return null;
};
